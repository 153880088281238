import { IAddress } from './address.interface';
import { ICollaboratorSalary } from './collaborator-salary.interface';
import { ILevelSkill } from './collaborator-skills.interface';
import { MaritalEnum } from '../enum/marital.enum';
import { DaysOfWeekEnum } from '../enum/working-days.enum';

export interface ICollaborator {
  id?: string;
  email: string;
  personalEmail?: string;
 // editableEmail?: boolean;
  editableIsLeader?: boolean;
  fullName: string;
  nickname: string;
  gender?: string;
  birthday: string;
  maritalStatus: MaritalEnum;
  children: boolean;
  isLeader: boolean;
  accessProfileId?: string;
  admission: string;
  contractTypeId: string;
  workingHours: number;
  jobFunctionId: string;
  teamId: string;
  seniorityId: string;
  collaboratorLeaderId: string;
  towerId: string;
  collaboratorSalaryData?: ICollaboratorSalary;
  collaboratorSkills: ILevelSkill[];
  collaboratorSchedule: {
    daysOfWeek: number;
    workingDays: Array<DaysOfWeekEnum>;
  };
  address: IAddress[];
  inactive?: boolean;
  imageURL?: string;
}

export interface ICollaboratorMinified {
  collaboratorId: string;
  fullName: string;
  imageURL: string;
  jobFunctionId: string;
  jobFunctionDescription: string;
  collaboratorLeaderId: string;
  collaboratorLeaderName: string;
  teamId: string;
  teamDescription: string;
  towerId: string;
  towerDescription: string;
  inactive: boolean;
  excludedCollaborator: boolean;
}

export interface ICollaboratorFilter {
  searchString: string | null;
  teamId: string | null;
  jobFunctionId: string | null;
  towerId: string | null;
  skillsId: string | null;
  skillLevel?: number | null;
  city: string | null;
  inactive: boolean | null;
  page: number;
  take: number;
  orderByParam: {
    orderBy: number;
    asc: boolean;
  };
}

export enum OrderByCollaborator {
  NAME = 1,
  JOB_FUNCTION = 2,
  LEADER = 3,
  TEAM = 4,
  TOWER = 5,
}
